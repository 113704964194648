h1
  +font-size(60, 68)
  font-family: "Helvetica", sans-serif
  font-weight: normal
  letter-spacing: 4px

h2
  +font-size(24, 34)
  letter-spacing: 2.4px
  font-family: "Garamond", sans-serif
  font-weight: bold

h4
  font-family: "Helvetica", sans-serif
  font-weight: normal
  letter-spacing: 4px

p
  font-family: "Gotham", sans-serif
  font-weight: lighter

h1, h2, h3, h4, h5, h6, a, p, span, div
  color: $c-lightgrey

  .dark-mode &
    color: $c-darkgrey