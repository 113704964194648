.breadcrumb
  text-align: center
  margin: 100px auto 30px
  display: block
  height: 66px
  position: relative

  .portfolio &
    margin: 100px auto 80px

  +mq($from: 1338px)
    position: static

  ul
    display: flex
    justify-content: center
    align-items: center

  span
    margin: 0 4px 0
    font-weight: normal
    text-transform: uppercase
    +font-size(10, 12)
    font-weight: bold
    letter-spacing: 1.6px

  span.current
    color: $c-orange

    .dark-mode &
      color: $c-red

  &__back
    display: flex
    justify-content: flex-start
    align-items: center
    position: absolute
    left: 50%
    transform: translateX(-50%)
    bottom: 0
    height: 14px
    cursor: pointer
    +transition()

    +mq($from: tablet)
      left: 0
      transform: none

    +mq($from: 1000px)
      left: auto
      +transition()

    +mq($from: 1338px)
      position: fixed
      transition: none
      top: 212px
      left: 120px

    &:hover, &:focus
      +transition

      span
        text-decoration: underline
        color: $c-orange
        +transition

        .dark-mode &
          color: $c-red

      .icon svg
        fill: $c-orange
        +transition

        .dark-mode &
          fill: $c-red

    .icon
      height: 14px
      display: block
      margin-right: 12px

      svg
        width: auto
        height: 100%
        fill: $c-lightgrey
        +transition

        .dark-mode &
          fill: $c-darkgrey

    span
      text-transform: uppercase
      +font-size(12, 14)
      font-weight: bold
      font-family: "Gotham", sans-serif
      +transition