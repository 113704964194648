.imprint
  +backgroundBlackCircle

  .dark-mode &
    +backgroundWhiteCircle

  &__container
    max-width: 620px
    margin: 0 auto
    padding-bottom: 160px
    padding-top: 100px

    h1
      font-family: "Garamond", sans-serif
      font-weight: bold
      +font-size(20, 22)
      letter-spacing: 2px
      margin-bottom: 40px
      margin-left: -48px

      .inspiration &
        margin-left: 0
        +font-size(32, 42)
        letter-spacing: 3.6px
        margin-bottom: 20px

    & > p, h3
      font-family: "Gotham Book", sans-serif
      +font-size(16, 28)
      letter-spacing: 0.16px

    h3
      font-weight: bold
      margin-bottom: 10px

    h4
      font-weight: bold
      +font-size(16, 16)
      letter-spacing: 1.6px
      font-family: "Gotham", sans-serif
      margin-bottom: 20px
      text-transform: uppercase

    b
      margin-bottom: 60px
      color: $c-orange
      text-transform: uppercase
      display: block
      +font-size(12, 16)
      letter-spacing: 1.2px

      .dark-mode &
        color: $c-red

    p
      &:not(:last-of-type)
        margin-bottom: 40px

        .inspiration &
          margin-bottom: 20px
