.notification
  height: 48px
  width: 100%
  background-color: #C8C8C8

  &__content
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    font-weight: normal
    +font-size(17, 22)

    b
      font-weight: bold
