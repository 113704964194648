.quote

  .uber__stage
    max-width: 700px
    animation: none
    padding-bottom: 160px
    margin-top: 120px
    position: relative

  &__close, &__open
    position: absolute
    color: $c-orange
    right: -24px
    top: -24px
    padding: 24px
    cursor: pointer
    display: block
    pointer-events: all
    transition: 0.2s 0s all ease-in

    .space--open &
      display: none
      pointer-events: none

    &:hover, &:focus
      transform: scale(2)
      transition: 0.2s 0s all ease-in

    .dark-mode &
      color: $c-red

  &__open
    font-family: "Gotham Book", sans-serif
    font-weight: normal
    +font-size(28, 32)

  h1
    font-family: "Gotham", sans-serif
    font-weight: bold
    +font-size(80, 100)
    margin-bottom: 120px
    color: $c-white
    letter-spacing: 0

    .dark-mode &
      color: $c-darkgrey

  &__line
    display: block
    width: 90px
    height: 4px
    margin-bottom: 60px
    background-color: $c-orange

    .dark-mode &
      background-color: $c-red

  h4
    font-family: "Gotham", sans-serif
    font-weight: bold
    +font-size(16, 18)
    letter-spacing: 0
    margin-bottom: 20px

  p
    font-family: "Gotham Thin", sans-serif
    +font-size(14, 16)
