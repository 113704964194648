.reel

  &__container
    height: 507px
    +transition()

    +mq($from: wqhd)
      margin-top: 180px
      +transition()

  &__object
    max-width: 1360px
    margin: 0 auto

    .tns-ovh
      margin: -60px

  &__header
    max-width: 1360px
    margin: 0 auto
    display: block

    h1
      margin-bottom: 40px
      display: block
      font-weight: normal
      letter-spacing: 4px
      +font-size(60, 68)

  &__sub
    margin-bottom: 80px

    .portfolio &
      max-width: 550px
      display: flex
      justify-content: space-between

    span
      display: inline-block
      height: 18px
      border-left: 1px solid $c-lightgrey
      width: 2px

      &:last-of-type
        display: none

    +mq($from: desktop)
      margin-bottom: 60px

    h4
      font-family: "Garamond", sans-serif
      display: inline-block
      text-transform: uppercase
      cursor: pointer
      letter-spacing: 3.84px
      +font-size(16, 18)
      transition: none

      &:first-of-type
        min-width: 138px
        text-align: center
      &:nth-of-type(2)
        min-width: 210px
        text-align: center
      &:last-of-type
        min-width: 165px
        text-align: center

      +mq($until: 680px)
        display: block

      &:hover, &:focus
        color: rgba($c-orange, .8)
        +transition()

        .dark-mode &
          color: rgba($c-red, .8)
          +transition()

      &.active
        font-family: "Gotham", sans-serif
        font-weight: bold
        color: $c-orange
        letter-spacing: 3.84px
        +font-size(16, 18)
        transition: none

        .dark-mode &
          color: $c-red

      &:not(:last-of-type)
        //padding-right: 10px
        //margin-right: 10px
        //border-right: 1px solid $c-lightgrey

        +mq($until: 680px)
          border-right: none

  &__wrapper
    position: relative
    height: 420px

    .reel-card
      +dropShadow

  &__track
    width: 100%
    height: 100%
    transition: 0.6s 0s ease-in-out

  &__inner
    position: absolute
    left: 0
    top: 0
    opacity: 0
    transition: 0.6s 0.05s ease-in-out
    display: block
    //padding: 42px
    animation: 0.9s 0s slideOutLeft forwards

    &--slider
      position: relative
      display: flex
      flex-wrap: nowrap
      overflow-x: auto
      -webkit-overflow-scrolling: touch

      .reel-card
        flex: 0 0 auto

        &:not(:last-of-type)
          margin-right: 40px

    & > .reel__no-slider
      max-height: 371px
      width: max-content
      display: block
      white-space: nowrap
      padding-right: 128px
      +transition()

      +mq($from: desktop)
        padding-right: 46px
        +transition()

      & > a, & > .reel-card
        display: inline-block
        border-radius: 8px

        .reel-card
          border-radius: 8px

          .reel-card__asset
            border-radius: 8px

            img
              border-radius: 8px
              overflow: hidden

        &:not(:last-of-type)
          margin-right: 40px

    &.active
      opacity: 1
      animation: 0.9s 0s slideInRight forwards
      width: 100%

      &.reel--static
        animation: none

      &::-webkit-scrollbar-track
        -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3)
        background-color: #F5F5F5

      &::-webkit-scrollbar
        width: 8px
        height: 8px
        background-color: #F5F5F5

      &::-webkit-scrollbar-thumb
        //background-color: #000000
        background-color: $c-red
        border: 0px solid #555555

    &:nth-child(odd)
      margin-right: 80px

    //a:first-of-type .reel-card
    //  margin-right: 40px

    .reel-card
      height: 100%
      border-radius: 8px

  &__actual-slider a
    display: inline-block
    vertical-align: top
    white-space: normal

    .reel-card
      +transition()

      &:hover, &:focus
        transform: scale(1.06)
        +transition()

  &__actions
    margin-top: 40px
    height: 40px
    margin-bottom: 60px

  &__detail
    max-width: 1360px
    margin: 0 auto
    position: relative
    height: auto
    //padding-bottom: 40px

    .detail &
      max-width: 1240px

    .tns-inner
      padding: 60px

    .tns-nav
      position: absolute
      bottom: -20px
      left: 50%
      display: flex
      justify-content: center
      align-items: center
      transform: translateX(-50%)

      button
        border: 0
        border-radius: 0
        width: 8px
        height: 2px
        margin: 0 10px
        pointer-events: none

        &.tns-nav-active
          background-color: $c-orange

          .dark-mode &
            background-color: $c-red

  //&:not(:last-of-type)
  //  margin-right: 20px

  &__arrow
    width: 24px
    height: 24px
    display: block
    position: absolute
    top: 50%
    left: -114px
    transform: translateY(-50%)
    cursor: pointer

    svg
      width: auto
      height: 100%
      display: block
      fill: $c-white
      +transition()

      .dark-mode &
        fill: $c-darkgrey

    &:hover, &:focus
      svg
        fill: $c-orange
        +transition()

        .dark-mode &
          fill: $c-red

    &--left--1
      display: none

    &--right
      left: auto
      right: -120px
      transform: translateY(-50%) rotate(180deg)

      .portfolio &
        right: -114px

      &--1
        display: none

@keyframes slideInRight
  0%
    transform: translateX(120%)

  100%
    transform: translateX(0%)

@keyframes slideOutLeft
  0%
    transform: translateX(0%)

  100%
    transform: translateX(-120%)
