.section
  position: relative

  &__headline
    max-width: 520px
    margin: 0 auto 80px auto
    color: $c-white
    text-align: center
    +font-size(24, 34)
    font-weight: 200
    letter-spacing: 2.4px
    font-family: "Garamond", sans-serif
    text-transform: uppercase

    sup
      font-size: 50%
      line-height: 0
      font-weight: 600
      margin-left: 8px
      position: relative
      text-transform: uppercase
      vertical-align: baseline
      top: -0.5em
      color: $c-orange

      .dark-mode &
        color: $c-red


