$c-white: #fff
$c-black: #000
$c-red: #F3073F
$c-darkgrey: rgba(35, 35, 35, 1)
$c-lightgrey: rgba(200, 200, 200, 1)
$c-grey: rgba(35, 35, 35, 0.32)
$c-orange: rgba(251, 181, 61, 1)
$olive: #26A69A
$black: #292929
$c-darkgrey: #232323
$bg: #191919
$lines: #757474


$c-primary: $c-lightgrey
