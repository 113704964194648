.scroll-up
  max-width: 110px
  display: block
  text-align: center

  &__wrapper
    width: 110px
    position: sticky
    bottom: 60px
    left: 50%
    transform: translateX(-50%)
    pointer-events: all
    cursor: pointer

  svg
    margin: 0 auto 8px auto
    display: block
    width: 14px
    height: 8px
    transform: rotate(90deg)
    fill: $c-white

    .dark-mode &
      fill: $c-black

    &:first-of-type
      opacity: .4

  & > span
    +font-size(10, 13)
    letter-spacing: 1.2px
    font-weight: bold
    color: $c-orange

    .dark-mode &
      color: $c-red

    span
      +font-size(10, 13)
      letter-spacing: 1.2px
      font-weight: bold
      color: $c-lightgrey

      .dark-mode &
        color: $c-black
