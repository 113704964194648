.contact
  +backgroundBlackCircle

  .dark-mode &
    +backgroundWhiteCircle

  &__container
    margin-top: 100px

  &__row
    display: flex
    flex-direction: column-reverse

    +mq($from: tablet)
      justify-content: space-around
      flex-direction: row

  &__col
    margin-bottom: 40px

    +mq($from: tablet)
      &:last-of-type
        margin-bottom: 0
        margin-left: 40px

    .detail__quote
      margin: 0 0 40px 0

      .dark-mode &
        color: $c-red

      +mq($from: tablet)
        margin: 0 0 76px -28px

  &__info
    display: block

    &:not(:last-of-type)
      margin-bottom: 42px

    & > p
      display: block

      &:first-of-type
        font-family: "Gotham", sans-serif
        font-weight: bold
        +font-size(16, 34)
        letter-spacing: 1.6px
        color: $c-orange

        .dark-mode &
          color: $c-red

      &:last-of-type
        font-family: "Garamond", sans-serif
        font-weight: normal
        letter-spacing: 2px
        +font-size(20, 34)

  &__image
    display: block
    padding: 24px
    box-sizing: border-box
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16)
    width: auto
    max-width: 280px
    +transition()

    +mq($from: desktop)
      padding: 38px
      max-width: 380px
      +transition()

    img
      width: 100%
      height: auto

