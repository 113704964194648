.balance

  .uber__stage
    animation: none
    margin: 140px 0 0 0
    max-width: none

  .detail__quote
    width: 320px
    left: 0
    margin: 0 0 140px 0

  &__icon
    width: 40px
    height: 40px
    margin: 0 auto 40px auto

    svg
      fill: $c-orange

      .dark-mode &
        fill: $c-red

  a
    display: block
    max-width: 400px
    margin: 0 auto


  &__destination
    text-align: center

    p
      +font-size(16, 24)
      font-family: "Gotham Book", sans-serif
