.card
  max-width: 400px
  height: 520px
  background-color: $c-darkgrey
  padding: 40px
  box-sizing: border-box

  span
    display: block

  &__inner
    display: flex
    flex-direction: column
    height: 100%

  &__actions
    display: block
    margin-bottom: 40px

    li
      display: inline-block
      list-style: none
      font-size: 18px

      &:first-of-type
        margin-right: 6px

  &__sub
    margin-bottom: 10px
    +font-size(12, 16)
    letter-spacing: 1.2px
    text-transform: uppercase
    font-weight: bold

  &__title
    margin-bottom: 20px
    +font-size(24, 26)
    font-weight: bold
    color: $c-orange

  &__desc
    font-size: 16px
    line-height: 22px

  .button
    margin-top: auto
    margin-bottom: 80px

  &__grid
    display: block
    justify-content: center
    max-width: 1384px
    margin: 0 auto
    padding-bottom: 140px

    &-title
      display: block
      text-transform: uppercase
      margin-bottom: 20px
      +font-size(16, 18)
      color: $c-orange

    &-inner
      display: flex
      flex-wrap: wrap
      width: 100%
      justify-content: space-between

      .card:not(:last-of-type)
        margin-right: 24px


