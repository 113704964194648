@font-face
  font-family: "Helvetica"
  src: url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.eot")
  src: url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.woff") format("woff"), url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/74fc282ac1937b053f409b3b70eb1bb6.svg#Helvetica") format("svg")

@font-face
  font-family: "Gotham"
  font-weight: bold
  src: url("../../fonts/Gotham Bold.otf") format("opentype")

@font-face
  font-family: "Gotham"
  font-weight: normal
  src: url("../../fonts/Gotham Medium.otf") format("opentype")

@font-face
  font-family: "Gotham Book Italic"
  src: url("../../fonts/GothamBookItalic.ttf")

@font-face
  font-family: "Gotham Book"
  src: url("../../fonts/Gotham Book.otf") format("opentype")

@font-face
  font-family: "Gotham Thin"
  src: url("../../fonts/Gotham Thin.otf") format("opentype")

@font-face
  font-family: "Garamond"
  font-weight: bold
  src: url("../../fonts/EBGaramond-VariableFont_wght.ttf")

@font-face
  font-family: "Garamond"
  font-weight: normal
  src: url("../../fonts/EBGaramond-VariableFont_wght.ttf")

