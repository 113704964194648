.detail
  padding-bottom: 120px

  &__hero
    border-radius: 8px
    overflow: hidden
    line-height: 0
    margin: 0 auto
    max-width: 1090px
    +dropShadow

  &__header
    color: $c-white
    margin: 0 auto 160px auto
    max-width: 1090px

    & > .detail__row:first-of-type
      justify-content: space-between

      +mq($until: desktop)
        flex-direction: column

      .detail__col:last-of-type
        width: auto

    .detail__col
      font-family: "Gotham", sans-serif

      +mq($until: desktop)
        width: 100%


      h1
        +font-size(20, 34)
        text-transform: uppercase
        letter-spacing: 2px
        font-family: "Gotham", sans-serif
        font-weight: bold
        margin-bottom: 32px

      p
        +font-size(16, 24)
        font-family: "Gotham", sans-serif
        font-weight: lighter
        margin-bottom: 20px

      a
        +font-size(16, 34)
        font-weight: normal
        color: $c-orange

        .dark-mode &
          color: $c-red

  &__row
    display: flex

    +mq($until: mobile)
      position: relative

  &__col
    width: 50%
    display: inline-block

    +mq($until: mobile)
      position: absolute
      left: 0

  &__info
    width: 194px

    & + .detail__info
      margin-left: 62px

    h4
      +font-size(16, 34)
      font-family: "Gotham", sans-serif
      font-weight: bold
      letter-spacing: 0

    p
      +font-size(16, 34)
      font-weight: normal
      color: $c-orange

      .dark-mode &
        color: $c-red

  &__media
    width: 100%
    margin-bottom: 164px

  &__screens
    width: 100%
    position: relative
    display: flex
    justify-content: space-between
    align-items: center
    padding-bottom: 24px
    +transition()

    +mq($from: desktop)
      padding-bottom: 48px
      +transition()

    +mq($from: widescreen)
      padding-bottom: 96px
      +transition()

    img
      line-height: 0
      display: inline-block
      box-sizing: border-box
      border-radius: 8px
      max-width: 520px
      position: absolute
      left: 0
      object-fit: cover
      object-position: top center
      width: auto
      height: 100%
      max-height: 780px
      +transition()
      +dropShadow

      +mq($from: tablet)
        max-height: 1008px

      +mq($from: desktop)
        max-height: 1152px

      +mq($from: widescreen)
        max-height: 1440px
        height: 100%

      &:last-of-type
        left: auto
        right: 0

      &:nth-of-type(2)
        padding: 0
        position: relative
        margin: 0 auto
        z-index: 5
        width: auto
        height: 100%
        max-height: 880px

        +mq($from: tablet)
          max-height: 1148px

        +mq($from: desktop)
          max-height: 1312px

        +mq($from: widescreen)
          max-width: 520px
          max-height: 1640px
          width: auto
          height: 100%

  &__quote
    max-width: 520px
    margin: 120px auto 60px auto
    +font-size(16, 24)
    font-family: "Gotham Book Italic", sans-serif
    color: $c-orange

    .dark-mode &
      color: $c-red

    p
      padding: 0 28px
      color: $c-orange
      font-family: "Gotham Book Italic", sans-serif

      .dark-mode &
        color: $c-red

    i
      display: block
      +font-size(40, 24)
      color: $c-orange

      .dark-mode &
        color: $c-red

      &:last-of-type
        text-align: right


