=transition
  transition: 0.2s 0s all ease-in

=font-size($px, $lh)
  font-size: $px + px
  line-height: $lh + px

=backgroundBlackCircle
  background: rgb(0, 0, 0)
  background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgb(50, 49, 49) 0%, rgba(0, 0, 0, 1) 100%)

=backgroundWhiteCircle
  background: rgb(255, 255, 255)

=dropShadow
  -webkit-box-shadow: 1px 1px 24px -2px rgba(0, 0, 0, 0.4)
  -moz-box-shadow: 1px 1px 24px -2px rgba(0, 0, 0, 0.4)
  box-shadow: 1px 1px 24px -2px rgba(0, 0, 0, 0.4)


