html, body
  font-family: "Gotham", sans-serif
  font-weight: bold
  scroll-behavior: smooth
  min-width: 320px

.App
  max-width: 1440px
  height: auto
  margin: 0 auto

#root
  overflow-x: hidden

.content
  margin: 0 auto
  padding: 60px 20px 0
  max-width: 1360px
  min-height: 100vh
  +transition
  //padding-bottom: 250px

  .portfolio &
    max-width: none

  +mq($from: tablet)
    padding: 60px 60px 0
    +transition

  +mq($from: desktop)
    padding: 60px 120px 0
    +transition

  &__inner
    max-width: 1080px
    margin: 0 auto

    &--detail
      max-width: 1346px
      margin: 0 auto

    &--life
      max-width: 1087px
      margin: 0 auto

  &__background
    position: absolute
    top: 0%
    left: 4%
    height: auto
    width: 48%
    z-index: -1
    opacity: 0.04
    animation: 0.4s 0s scaleFromLeft forwards

.home, .detail, .design, .power
  +backgroundBlackCircle
  min-height: 100vh
  +transition

  .dark-mode &
    +backgroundWhiteCircle
    +transition

.home
  overflow: hidden
  position: relative
  perspective: 40px
  +transition

a
  text-decoration: none

@keyframes scaleFromLeft
  0%
    left: 4%
    top: 0%
    transform: scale(1)

  100%
    left: 16%
    top: 16%
    transform: scale(1.1)
