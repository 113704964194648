.life
  background: $c-black
  overflow: hidden

  .dark-mode &
    +backgroundWhiteCircle

  &__hero
    min-height: 100vh
    position: relative

  &__background
    position: fixed
    top: 0
    left: 50%
    transform: translateX(-50%)
    height: auto
    opacity: 0.16
    pointer-events: none

    .dark-mode &
      opacity: 0.04

  &__upper
    height: 60vh
    position: relative

    .detail__quote
      margin: 0
      position: absolute
      left: 0
      bottom: 140px
      color: $c-orange

      .dark-mode &
        color: $c-red

      p
        color: $c-orange

        .dark-mode &
          color: $c-red

  .detail__row
    margin: -23.5px
    display: flex

    .detail__col
      margin: 45px
      max-width: 520px
      box-sizing: border-box

      h2
        +font-size(59, 65)
        letter-spacing: 14.16px
        font-weight: bold
        margin-bottom: 20px
        text-transform: uppercase
        font-family: "Gotham", sans-serif

      h4
        +font-size(24, 34)
        letter-spacing: 2.4px
        font-weight: bold
        text-transform: uppercase
        margin-bottom: 20px
        font-family: "Garamond", sans-serif

        i
          color: $c-orange
          +font-size(12, 34)
          vertical-align: super

          .dark-mode &
            color: $c-red

      p
        font-family: "Gotham Book", sans-serif
        +font-size(16, 24)

    &:nth-of-type(odd)
      text-align: right

      .detail__col:last-of-type
        filter: blur(8px)
        opacity: 0.4
        transform: scale(0.5)
        transform-origin: left top

    &:nth-of-type(even) .detail__col:first-of-type
      filter: blur(8px)
      opacity: 0.4
      transform: scale(0.5)
      transform-origin: right top

  &__explanation
    position: absolute
    right: 0
    padding-right: 0
    +transition

    +mq($from: desktop)
      padding-right: 200px

    i
      cursor: pointer
      font-weight: bold
      color: $c-red
      +font-size(64, 64)
      height: 32px
      display: block
      margin-bottom: 20px
      +transition

      &:hover,
      &:focus
        color: $c-black
        transition: all 0.3ms 0s ease-in-out
        +transition

        & ~ p
          display: block

    p
      display: none
      +font-size(16, 22)
      padding-left: 40px
