.navbar
  width: 100%
  display: flex
  height: 40px
  justify-content: space-between
  align-items: center
  padding: 0 20px
  box-sizing: border-box
  position: fixed
  z-index: 100
  margin-top: 32px
  top: 0
  left: 0
  right: 0
  +transition()

  +mq($from: tablet)
    padding: 0 60px
    +transition()
  +mq($from: desktop)
    padding: 0 120px
    +transition()

  &__logo
    display: inline-block
    width: 42px
    height: 40px

  &__links, &__actions
    height: 100%

  ul
    height: 100%

    li
      display: inline-flex
      list-style: none
      align-items: center
      height: 100%
      border-bottom: 2px solid transparent

      //&.active, &:hover, &:focus
      //  border-bottom: 2px solid $c-lightgrey

      //&:not(:last-of-type)
      //  margin-right: 20px

      span
        cursor: pointer
        text-decoration: none
        font-weight: normal
        letter-spacing: 1.14px
        +font-size(14, 26)
        text-transform: uppercase
        +transition

        &:hover, &:focus
          color: $c-orange
          +transition

  &__actions

    &--active li svg
      transform: rotate(180deg)
      +transition()

    li:first-of-type
      margin-right: 80px

    svg
      height: 16px
      width: 18px
      display: block
      fill: $c-white
      +transition()

      .dark-mode &
        fill: $c-darkgrey
        +transition()



