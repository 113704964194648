.reel-card
  display: inherit
  //height: 528px
  //transition: 0.2s 0s scale ease-in
  .reel__no-slider--hover &
    +transition()

  &:hover, &:focus
    .reel__no-slider--hover &
      transform: scale(1.05)
      +transition

  &--modal

    & + .modalBackground
      display: none

    &-active
      position: fixed
      //transform: translate(-50%, -50%)
      left: 0
      top: -50%
      width: 50%
      max-width: 1360px
      height: auto !important
      z-index: 100
      transition: none
      animation: 0.8s 0s fadeIn ease-in-out
      transform: scale(0.8)

      & + .modalBackground
        display: block
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: $c-black
        opacity: .6
        z-index: 10
        pointer-events: none

      .reel-card__asset
        max-width: none
        max-height: none

        img
          width: 100%
          height: auto

  &__asset
    display: block
    max-width: 438px
    line-height: 0
    +transition

    +mq($from: tablet)
      max-width: 658px

    .reel__container--round &
      border-radius: 8px
      overflow: hidden

    .reel__no-slider &
      +dropShadow

    img
      width: 100%
      height: auto
      object-fit: cover

  .card
    display: inline-block
    max-width: 480px
    height: 100%


#container
  perspective: 40px

#inner
  transition: all 0.3s ease-in-out

@keyframes fadeIn
  0%
    opacity: 0

  100%
    opacity: 1