.button
  height: 48px
  max-width: 240px
  box-sizing: border-box
  border: 1px solid $c-orange
  border-radius: 4px
  +font-size(16, 26)
  font-weight: bold
  text-transform: uppercase

  text-align: center
  background-color: transparent
  cursor: pointer
  +transition

  &:hover, &:focus
    background-color: $c-orange
    color: $c-black
    transform: scale(1.1)
    +transition
