.uber
  min-height: 100vh
  +backgroundBlackCircle

  .dark-mode &
    +backgroundWhiteCircle

  &__row
    display: flex
    margin: 200px auto 0

    &:first-of-type
      margin: 140px auto 0

      +mq($from: wqhd)
        margin: 240px auto 0

    .detail__quote
      margin: 0 auto
      left: 50%
      transform: translateX(-50%)
      bottom: auto

    .detail__quote i:last-of-type
      text-align: center

    &--hidden
      display: none

  &__col
    width: 33%
    text-align: center

    h1
      font-family: "Gotham", sans-serif
      font-weight: bold
      +font-size(28, 26)
      letter-spacing: 2.8px
      text-transform: uppercase

    & > span, a
      width: 64px
      height: 64px
      display: block
      margin: 100px auto 0
      cursor: pointer

      svg
        transition: 0.2s 0s all ease-in !important
        fill: $c-white

        .dark-mode &
          fill: $c-darkgrey

      &:hover, &:focus
        svg
          transform: scale(1.1)
          fill: $c-orange
          +transition()

          .dark-mode &
            fill: $c-red

  &__stage
    display: block
    max-width: 60%
    margin: 160px auto 0
    animation: 9s 0s fadeOutFast forwards
    z-index: 20

@keyframes fadeOutFast
  0%
    opacity: 1
    visibility: visible
    display: block

  95%
    opacity: 1
    visibility: visible
    display: block

  100%
    opacity: 0
    visibility: hidden
    display: none




