.space
  display: flex
  height: 100%
  +transition

  &__background
    position: absolute
    top: 50%
    left: 4%
    height: auto
    opacity: 0
    width: 100%
    height: 100%
    +transition()
    transform: translate(-20%, -65%)

    +mq($until: desktop)
      width: 100%
      height: 100%
      left: 4%
      top: 0%
      +transition()

    .space--open &
      opacity: 0.04
      +transition()

  &__intro
    display: flex
    justify-content: center
    align-items: center
    position: relative

    &-arrow
      width: 48px
      height: 48px
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      pointer-events: all
      margin: 40px
      +transition()

      +mq($from: widescreen)
        margin: 80px
        +transition()

      &:hover, &:focus
        transform: scale(1.4)
        +transition()

      .space--open &
        display: none

      &:last-of-type
        transform: rotate(180deg)

        &:hover, &:focus
          transform: scale(1.4) rotate(180deg)
          +transition()

      svg
        display: inline-block
        width: 12px
        height: 16px
        fill: $c-white

        .dark-mode &
          fill: $c-black

        &:first-of-type
          opacity: 0.4

  &__container
    display: block
    width: 100%
    height: 100%
    top: 0
    position: absolute
    display: flex
    flex-direction: column

  &__row
    display: flex
    flex-direction: column
    justify-content: space-between
    align-self: center
    margin: auto 0
    padding: 0 20px
    max-width: 1360px
    min-height: 420px
    width: 100%
    width: stretch
    +transition()

    & > div > div:nth-of-type(2)
      margin-bottom: 240px

    & > div > div:last-of-type
      margin-top: 340px

    +mq($from: tablet)
      padding: 0 60px
      +transition()

    +mq($from: desktop)
      flex-direction: row

    +mq($from: widescreen)
      padding: 0 60px
      +transition()

  &__header
    text-align: center
    visibility: hidden
    position: absolute
    top: 136px
    left: 50%
    transform: translateX(-50%)

    .space--open &
      visibility: visible

    span
      margin: 0 4px 0
      font-weight: normal
      text-transform: uppercase
      +font-size(12, 13)
      font-weight: bold
      letter-spacing: 1.2px

    span:last-of-type
      color: $c-orange

      .dark-mode &
        color: $c-red

  &__column
    height: 100%
    display: none

    +mq($from: desktop)
      flex: 1 1

    .space--open &
      display: block

    &--middle
      display: block

    &:first-of-type
      .space__item:last-of-type
        width: 220px

    &:nth-of-type(2)
      display: block

    & > ul
      +mq($until: desktop)
        display: flex
        justify-content: space-evenly
        align-items: center
        flex-wrap: wrap

      & > i
        display: none
        width: 20px
        margin: 40px auto
        border-bottom: 1px solid $c-lightgrey
        opacity: .4
        +mq($from: desktop)
          display: block

      & > li
        text-transform: uppercase
        +font-size(28, 31)
        letter-spacing: 6.72px

        a
          font-family: "Garamond", sans-serif
          display: block
          font-weight: normal
          padding: 10px

          &:hover, &:focus
            font-family: "Gotham", sans-serif
            font-weight: bold
            color: $c-orange

            .dark-mode &
              color: $c-red

        &:nth-of-type(2)
          text-align: right
          margin-left: 60px

        &:nth-of-type(3)
          margin-right: 40px

    &:nth-of-type(2)
      flex: 3 3

    &-logo
      padding: 20px
      box-sizing: content-box
      text-align: center
      min-width: 220px
      +transition()

      +mq($from: tablet)
        padding: 40px
        +transition()
      +mq($from: desktop)
        padding: 80px
        +transition()

      img
        max-width: 320px
        width: 100%
        height: auto
        cursor: pointer
        transform: scale(1.5) translateX(-44px)
        +transition

        .space--open &
          transform: scale(1) translateX(-44px)
          +transition

    &:last-of-type
      flex-direction: column
      justify-content: flex-end

      .space__item:nth-of-type(2)
        width: 240px

      .space--open &
        display: block

  &__item
    display: inline-block
    position: relative

    +mq($from: desktop)
      display: block

    a
      position: relative

      &:hover ~ .space__item-details,
      &:focus ~ .space__item-details
        opacity: 1


    &-details
      position: absolute
      display: block
      opacity: 0
      text-align: left
      left: 50%
      transform: translateX(-25%)
      width: 100%

      img
        width: 100%

      & > ul > li
        list-style: none
        font-weight: normal
        +font-size(10, 16)
        letter-spacing: 5px

        i
          color: $c-red
          font-weight: bold
          +font-size(10, 11)
          letter-spacing: 5px

  &__column--first .space__item
    &:first-of-type .space__item-details
      top: auto
      left: 56%
      bottom: 84%
      width: 274px

    &:nth-of-type(2) .space__item-details
      left: 100%
      width: 281px

    &:last-of-type .space__item-details
      top: 105%
      left: -54%
      bottom: auto
      width: 197px

  &__column--second .space__item:first-of-type .space__item-details
    left: 50%
    top: -120%
    width: 274px

  &__column--second .space__item:nth-of-type(2) .space__item-details
    left: 50%
    width: 279px

  &__column--second .space__item:last-of-type .space__item-details
    width: 90%
    left: -40%
    width: 197px

  &__lower
    display: flex
    position: absolute
    bottom: 40px
    left: 0
    right: 0
    padding: 0 140px
    justify-content: space-between
    align-items: center

    span
      +font-size(12, 13)
      letter-spacing: 6.72px
      font-family: "Garamond", sans-serif
      color: $c-lightgrey
      text-transform: uppercase
      font-weight: lighter

      &:hover
        color: $c-orange

        .dark-mode &
          color: $c-red

      .dark-mode &
        color: $c-darkgrey

